import { Routes } from '@angular/router';

export const AppRoutes: Routes = [

  {
    path: 'product-details',
    loadChildren: () =>
      import(
        /* webpackChunkName: "product-details-routes" */ './modules/products/products-routing.module'
      ).then((x) => x.ProductRoutes),
  },
  {
    path: 'category',
    loadChildren: () =>
      import(
        /* webpackChunkName: "category-page-routes" */ './modules/category/category-routing.module'
      ).then((x) => x.CategoryRoutes),
  },
  {
    path: 'vendors',
    loadChildren: () =>
      import(
        /* webpackChunkName: "category-page-routes" */ './modules/vendors/vendor-routing.module'
      ).then((x) => x.VendorRoutes),
  },
  {
    path: '',
    loadChildren: () =>
      import(
        /* webpackChunkName: "search-result-routes" */ './modules/search/search-routing.module'
      ).then((x) => x.SearchRoutes),
  },
  {
    path: 'account',
    loadChildren: () => import('@ea/account').then((r) => r.ACCOUNT_ROUTES),
  },
  ];