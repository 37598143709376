import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, defer, map, of, switchMap, tap } from 'rxjs';

import { cartActions } from './cart.actions';
import { CartService } from '../services';
import { authActions } from '@ea/auth';
import { getDefaultCart } from '../models';
import { ToastService } from '@ea/services';
import { LocalStorageService } from '@ea/services';

@Injectable()
export class CartEffects {
  reload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.set, authActions.loginSuccess),
      switchMap((userState) =>
        defer(() => {
          if (userState.user.customId) {
            const result = cartActions.find({
              customId: userState.user.customId,
            });

            return of(result);
          } else if (this.storageService.getItem('cartId')) {
            const result = cartActions.get({
              cartId: this.storageService.getItem('cartId'),
            });

            return of(result);
          } else {
            return of(cartActions.set(getDefaultCart('')));
          }
        })
      )
    )
  );

  cleanLocally$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.logout),
      switchMap(() => defer(() => of(cartActions.set(getDefaultCart()))))
    )
  );

  find$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cartActions.find),
      switchMap(({ customId }) =>
        this.cartService
          .find(customId ?? '')
          .pipe(
            map((cart) =>
              cartActions.set(cart.carts[0] ?? getDefaultCart(customId))
            )
          )
      )
    )
  );

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cartActions.get),
      switchMap(({ cartId }) =>
        this.cartService
          .get(cartId ?? '')
          .pipe(map((cart) => cartActions.set(cart ?? getDefaultCart())))
      )
    )
  );

  applyPromoCode$ =  createEffect(() =>this.actions$.pipe(
    ofType(cartActions.applyPromoCode),
    switchMap((request) =>
       this.cartService.applyPromoCode(request.cartId, request.promoCode).pipe(
        switchMap(() => {
          this.toasterService.show(
            `Promo Code applied`
          );
          return of(cartActions.get({ cartId: request.cartId }));
        }),
        catchError((e) => {
          this.toasterService.show(e.error);
          return of(cartActions.get({ cartId: request.cartId }));
        }
      ))
    )));

    
  removePromoCode$ =  createEffect(() =>this.actions$.pipe(
    ofType(cartActions.removePromoCode),
    switchMap((request) =>
       this.cartService.removePromoCode(request.cartId, request.promoCode).pipe(
        switchMap(() => {
          this.toasterService.show(
            `Promo Code removed`
          );
          return of(cartActions.get({ cartId: request.cartId }));
        }),
        catchError((e) => {
          this.toasterService.show(e.error);
          return of(cartActions.get({ cartId: request.cartId }));
        }
      ))
    )));


  addProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cartActions.addProduct),
      switchMap((request) =>
        this.cartService.addItem(request).pipe(
          switchMap((response) => {
            if (response.carts) {
              // Check if items exist in the cart
              if (
                response.carts[0].items &&
                response.carts[0].items.length > 0
              ) {
                const addedItemTitle = response.carts[0].items[0].extra.parentName.value;
                this.storageService.setItem('cartId', response.carts[0].cartId);
                this.storageService.setItem('meta-name', response.carts[0].metadata.name)
                this.toasterService.show(
                  `You have successfully added ${addedItemTitle} in your basket!`
                );
              }

              return of(cartActions.set(response.carts[0]));
            } else {
              return of(cartActions.get({ cartId: request.cartId }));
            }
          }),
          catchError((e) => {
            if (e.error == 'Out of Stock') {
              this.toasterService.show(`Out of Stock`);
            } else {
              this.toasterService.show(`something went wrong`);
            }
            return of(cartActions.get({ cartId: request.cartId }));
          })
        )
      )
    )
  );

  // updateProduct$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(cartActions.updateProduct),
  //     switchMap((params) =>
  //       this.cartService.updateItem(params.cartId, params.request).pipe(
  //         switchMap((response) => {
  //           if (response && response.cartId) {
  //             this.toasterService.show(
  //               `You have successfully updated an item in your cart!`
  //             );
  //             return of(cartActions.set(response));
  //           } else {
  //             return of(cartActions.get({ cartId: params.cartId }));
  //           }
  //         })
  //       )
  //     )
  //   )
  // );

  updateProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cartActions.updateProduct),
      switchMap((params) =>
        this.cartService.updateItem(params.cartId, params.request).pipe(
          switchMap((response) => {
            if (response && response.cartId) {
              // Check if items exist in the updated cart
              if (response.items && response.items.length > 0) {
                const updatedItemTitle = response.items[0].title;
                this.toasterService.show(
                  `You have successfully updated ${updatedItemTitle} in your basket!`
                );
              }

              return of(cartActions.set(response));
            } else {

              return of(cartActions.get({ cartId: params.cartId }));
            }
          }),
          catchError((x) =>{ 
            this.toasterService.show(
              `Not enough stock available for this item`
            ); 
            return of(cartActions.get({ cartId: params.cartId }));
          })
        ),
      )
    )
  );

  updateItemShippingMethod$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cartActions.updateItemShippingMethod),
      switchMap((params) =>
        this.cartService
          .setAddressToItem(
            params.cartId,
            params.address,
            params.shipMethod,
            params.items
          )
          .pipe(
            switchMap((response) => {
              return of(cartActions.get({ cartId: response.cartId }));
            })
          )
      )
    )
  );

  updateItemFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(cartActions.updateItemFailed),
        tap(() => {
          // this.toastrService.success('Failed updating the item in your cart!');
          this.toasterService.show(`Failed updating the item in your basket!`);
        })
      ),
    { dispatch: false }
  );

  removeProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cartActions.removeProduct),
      switchMap((request) =>
        this.cartService.removeItem(request.cartId, request.lineItemId).pipe(
          switchMap((response) => {
            if (response) {
              return of(cartActions.get({ cartId: request.cartId }));
            } else {
              return of(cartActions.updateItemFailed());
            }
          })
        )
      )
    )
  );

  loginWithCartItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cartActions.loginWithCartItems),
      switchMap((request) =>
        this.cartService
          .addItemsToUserCart(request.cartId, request.customId)
          .pipe(
            switchMap((r) => {
              const t =  cartActions.find({
                customId:  request.customId,
              });
              return of(t);
            })
          )
      )
    )
  );
  
  constructor(
    private actions$: Actions,
    private cartService: CartService,
    private toasterService: ToastService,
    private storageService: LocalStorageService
  ) {}
}
